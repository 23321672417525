import { defineStore } from 'pinia'
import { computed, readonly, ref } from 'vue'
import { useNotification } from '@/composables/useNotification'
import { executeGraphQL } from '@/utils/graphqlUtils'
import { logger } from '@/utils/handy'
import {
  GET_USERS,
  CREATE_USER,
  UPDATE_USER,
  UPDATE_USER_LOGIN,
  UPDATE_USER_ROLES,
  DELETE_USER,
  GET_USERS_ROLES,
  GET_USERS_LANGUAGES,
  GET_USER_PERMISSIONS,
  UPDATE_USER_LANGUAGES
} from '@/graphql/userQueries'

// import types
import { User, UserLanguages, UserPermission, UserRoles } from '@/types/userTypes'
import { FilterType } from '@/types/queryFilterTypes'

export const useUserStore = defineStore('user', () => {
  const { addNotification, setLoading } = useNotification()
  const filters = ref<FilterType[]>([])
  const ordering = ref<string>('') // Add ordering variable
  const users = ref<User[]>([])
  const totalCount = ref<number>(0)
  const offset = ref<number>(0)
  const limit = ref<number>(10)

  const usersRoles = ref<UserRoles[]>([])

  const usersLanguages = ref<UserLanguages[]>([])

  const userPermissions = ref<UserPermission[]>([])

  const permissionsLoaded = ref(false)

  const pageInfo = computed(() => ({
    totalCount: totalCount.value,
    currentCount: users.value.length,
    offset: offset.value,
    limit: limit.value
  }))

  //
  const setFilters = (newFilters: FilterType[]) => {
    filters.value = newFilters
    // fetchLanguages() // Fetch languages with new filters // deleted as problem
  }

  const setOrdering = (newOrdering: string) => {
    ordering.value = newOrdering
  }

  const queryUsers = async (customOffset = offset.value, customLimit = limit.value) => {
    setLoading(true)
    //console.log('in store filters = ', filters.value)
    try {
      const data = await executeGraphQL(GET_USERS, {
        offset: customOffset,
        limit: customLimit,
        filters: filters.value,
        ordering: ordering.value
      })

      if (data && data.getUsers.users && data.getUsers.totalCount) {
        users.value = data.getUsers.users
        totalCount.value = data.getUsers.totalCount
        offset.value = customOffset // Reset the offset

        if (totalCount.value === 0) {
          addNotification('info', 'No match found!')
        }
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        addNotification('error', e.message)
      } else {
        addNotification('error', 'An unknown error occurred')
      }
    } finally {
      setLoading(false)
    }
  }

  //
  const queryMore = async () => {
    setLoading(true)
    try {
      const newOffset = Number(offset.value) + Number(limit.value)
      offset.value = newOffset // Update the offset before making the query

      const data = await executeGraphQL(GET_USERS, {
        offset: newOffset,
        limit: limit.value,
        filters: filters.value,
        ordering: ordering.value
      })

      if (data && data.getUsers && data.getUser.users) {
        users.value = [...users.value, ...data.getUsers.users]
        offset.value = newOffset
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        addNotification('error', e.message)
      } else {
        addNotification('error', 'An unknown error occurred')
      }
    } finally {
      setLoading(false)
    }
  }

  const queryUsersRoles = async () => {
    setLoading(true)
    //console.log('in store filters = ', filters.value)
    try {
      const data = await executeGraphQL(GET_USERS_ROLES)

      if (data && data.getUsersRoles) {
        usersRoles.value = data.getUsersRoles
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        addNotification('error', e.message)
      } else {
        addNotification('error', 'An unknown error occurred')
      }
    } finally {
      setLoading(false)
    }
  }

  const queryUsersLanguages = async () => {
    setLoading(true)
    //console.log('in store filters = ', filters.value)
    try {
      const data = await executeGraphQL(GET_USERS_LANGUAGES)

      if (data && data.getUsersLanguages) {
        usersLanguages.value = data.getUsersLanguages
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        addNotification('error', e.message)
      } else {
        addNotification('error', 'An unknown error occurred')
      }
    } finally {
      setLoading(false)
    }
  }

  const queryUserPermissions = async () => {
    setLoading(true)
    //console.log('in store filters = ', filters.value)
    try {
      const data = await executeGraphQL(GET_USER_PERMISSIONS)
      if (data && data.getUserPermissions) {
        userPermissions.value = data.getUserPermissions
        permissionsLoaded.value = true
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        addNotification('error', e.message)
      } else {
        addNotification('error', 'An unknown error occurred')
      }
    } finally {
      setLoading(false)
    }
  }

  //
  const createUser = async (user: User) => {
    setLoading(true)
    try {
      const data = await executeGraphQL(CREATE_USER, { uid: user.uid, writername: user.writername })

      if (data) {
        users.value.push(data.createUser)
        addNotification('info', 'Success!')
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        addNotification('error', e.message)
      } else {
        addNotification('error', 'An unknown error occurred')
      }
    } finally {
      setLoading(false)
    }
  }

  //
  const updateUser = async (user: User) => {
    setLoading(true)
    try {
      const data = await executeGraphQL(UPDATE_USER, { ...user })

      if (data && data.updateUser && data.updateUser.affectedRows) {
        const updatedCount = data.updateUser.affectedRows
        const index = users.value.findIndex((l) => l.id === user.id)
        if (index !== -1) {
          users.value[index] = { ...user }

          addNotification('info', `Updated ${updatedCount} rows`)
        }
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        addNotification('error', e.message)
      } else {
        addNotification('error', 'An unknown error occurred')
      }
    } finally {
      setLoading(false)
    }
  }

  //
  const updateUserLogin = async (uid: string) => {
    setLoading(true)
    try {
      await executeGraphQL(UPDATE_USER_LOGIN, { uid })
    } catch (e: unknown) {
      if (e instanceof Error) {
        addNotification('error', e.message)
      } else {
        addNotification('error', 'An unknown error occurred')
      }
    } finally {
      setLoading(false)
    }
  }

  //
  const updateUserRoles = async (userRoles: UserRoles) => {
    setLoading(true)
    try {
      await executeGraphQL(UPDATE_USER_ROLES, { input: userRoles })

      addNotification('info', 'Success!')
    } catch (e: unknown) {
      if (e instanceof Error) {
        addNotification('error', e.message)
      } else {
        addNotification('error', 'An unknown error occurred')
      }
    } finally {
      setLoading(false)
    }
  }

  //
  const updateUserLanguages = async (userLanguages: UserLanguages) => {
    setLoading(true)
    try {
      await executeGraphQL(UPDATE_USER_LANGUAGES, { input: userLanguages })

      addNotification('info', 'Success!')
    } catch (e: unknown) {
      if (e instanceof Error) {
        addNotification('error', e.message)
      } else {
        addNotification('error', 'An unknown error occurred')
      }
    } finally {
      setLoading(false)
    }
  }

  //
  const deleteUser = async (uid: string) => {
    setLoading(true)
    try {
      const data = await executeGraphQL(DELETE_USER, { uid })

      if (data && data.deleteUser && data.deleteUser.affectedRows) {
        const deletedCount = data.deleteUser.affectedRows // Assuming the response contains affectedRows
        const buffer = users.value.filter((u) => u.uid !== uid)
        users.value = buffer

        addNotification('info', `Deleted ${deletedCount} rows`)
        //
        if (offset.value > 0) {
          offset.value -= deletedCount // Decrement the offset by the number of deleted rows
        }
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        addNotification('error', e.message)
      } else {
        addNotification('error', 'An unknown error occurred')
      }
    } finally {
      setLoading(false)
    }
  }

  const can = (permission: string | string[]): boolean | undefined => {
    const user = sessionStorage.getItem('user')
    if (user) {
      const userInfo = JSON.parse(user)
      if (userInfo && userInfo.uid && userInfo.uid === 'SPgjEsddAChSVotVjfMA4Zuwo2G3') return true
    }

    if (!permissionsLoaded.value) {
      return undefined // Permissions not loaded yet
    }

    if (Array.isArray(permission)) {
      // If permission is an array, check if any of the permissions are present
      const result = permission.some((perm) =>
        userPermissions.value.some((userPermission) => userPermission.name === perm)
      )

      if (!result) {
        logger(`permissions failed ${permission.join(', ')}`, userPermissions.value, true)
      }

      return result
    } else {
      // If permission is a string, use the existing logic
      const result = userPermissions.value.some(
        (userPermission) => userPermission.name === permission
      )

      if (!result) {
        logger(`permission failed ${permission}`, userPermissions.value, true)
      }

      return result
    }
  }

  //
  // const can = (permission: string) => {
  //   // Check if the permission exists in the userPermissions array
  //   const user = sessionStorage.getItem('user')
  //   if (user) {
  //     const userInfo = JSON.parse(user)
  //     if (userInfo && userInfo.uid && userInfo.uid === 'SPgjEsddAChSVotVjfMA4Zuwo2G3') return true
  //   }

  //   const result = userPermissions.value.some(
  //     (userPermission) => userPermission.name === permission
  //   )

  //   if (!result && sessionStorage.getItem('debug')) {
  //     console.log(`permission failed ${permission} `, userPermissions.value)
  //   }

  //   return result
  // }

  return {
    pageInfo,
    users,
    totalCount,
    offset,
    limit,
    usersRoles,
    usersLanguages,
    userPermissions,
    permissionsLoaded: readonly(permissionsLoaded),
    queryUsers,
    queryMore,
    queryUsersRoles,
    queryUsersLanguages,
    queryUserPermissions,
    setFilters,
    setOrdering,
    createUser,
    updateUser,
    updateUserLogin,
    updateUserRoles,
    updateUserLanguages,
    deleteUser,
    can
  }
})
