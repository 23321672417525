import { isRef, Ref } from 'vue'

import { mdiAccountMultiple } from '@mdi/js'

import { HasTranslations } from '@/types/registryTypes'
import {
  HierarchyRelation,
  MenuItem,
  SortingInfoType,
  TreeItemType,
  TreeNode
} from '@/types/viewFieldTypes'

export function dynamicSort(properties: SortingInfoType[]) {
  return function (a: any, b: any) {
    for (let i = 0; i < properties.length; i++) {
      const { field, direction } = properties[i]
      let aVal = a[field]
      let bVal = b[field]

      // Handle string comparisons case-insensitively
      if (typeof aVal === 'string' && typeof bVal === 'string') {
        aVal = aVal.toLowerCase()
        bVal = bVal.toLowerCase()
      }

      if (aVal < bVal) return direction === 'asc' ? -1 : 1
      if (aVal > bVal) return direction === 'asc' ? 1 : -1
    }
    return 0
  }
}

export const copyToClipboard = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text)
    // Optionally, you can show a success message to the user
  } catch (err) {
    console.error('Failed to copy text: ', err)
    // Optionally, you can show an error message to the user
  }
}

// find a translation of selected language
// @param object search for translations object in this
// @param int default language ID is 1 search for this language ID
// @param boolean whether to use isActive or all
export const localeEntry = function <T extends HasTranslations>(
  item: T | Ref<T>,
  languageId: number = 1,
  activeOnly: boolean = true,
  createDefault?: () => T['translations'][number]
): T['translations'][number] {
  // Check if item is a ref
  const itemValue = isRef(item) ? item.value : item

  //console.log('inside localeEntry itemValue = ', itemValue)

  // Check if itemValue has translations property
  // by making the type HasTranslations must have the translations property, we don't need following if condition
  //if (itemValue && 'translations' in itemValue && Array.isArray(itemValue.translations)) {

  // Try to find the translation for the requested languageId
  let translation = itemValue.translations.find(
    (c: any) => c.languageId === languageId && (activeOnly ? c.isActive : true)
  )

  // If not found and useDefault is true, try to find translation for languageId 1
  if (!translation) {
    translation = itemValue.translations.find(
      (c) => c.languageId === 1 && (activeOnly ? c.isActive : true)
    )
  }

  //console.log('inside localeEntry translation = ', translation)
  return translation || (createDefault ? createDefault() : ({} as T['translations'][number]))

  // remains of the removed if condition
  //}

  // If translations array doesn't exist or is not an array, return undefined
  //return undefined
}

//
export function isEmptyObject(obj: object): boolean {
  return Object.keys(obj).length === 0
}

//
// Function to generate an array of objects from the enum
export function generateEnumOptions(enumObj: any) {
  const aaa = Object.keys(enumObj)
    .filter((key) => isNaN(Number(key))) // Filter out numeric keys
    .map((key) => ({
      id: key,
      label: enumObj[key]
    }))
  return aaa
}

// Function to convert string to camel case
export function toCamelCase(str: string): string {
  console.log('str=', str)
  return str
    ? str
        .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
          return index === 0 ? word.toLowerCase() : word.toUpperCase()
        })
        .replace(/\s+/g, '')
    : ''
}

// Function to convert string to camel case
export function toLowerCase(str: string): string {
  console.log('str=', str)
  return str
    ? str
        .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, _index) => {
          return word.toLowerCase()
        })
        .replace(/\s+/g, '')
    : ''
}

//
export function camelJoin(str1: string, str2: string, joiner: string = '-'): string {
  // Convert both strings to camel case
  const camelStr1 = toCamelCase(str1)
  const camelStr2 = toCamelCase(str2)

  // Join the camel case strings with a hyphen
  return `${camelStr1}${joiner}${camelStr2}`
}

//
export function lowercaseJoin(str1: string, str2: string, joiner: string = '-'): string {
  // Convert both strings to camel case
  const camelStr1 = toLowerCase(str1)
  const camelStr2 = toLowerCase(str2)

  // Join the camel case strings with a hyphen
  return `${camelStr1}${joiner}${camelStr2}`
}

//
export function filterMenuByPermissions(
  menu: MenuItem[],
  userCan: (permission: string) => boolean
): MenuItem[] {
  return menu.reduce((acc: MenuItem[], item) => {
    if (item.requiredPermission && !userCan(item.requiredPermission)) {
      return acc
    }

    if (item.menu) {
      const filteredSubMenu = filterMenuByPermissions(item.menu, userCan)
      if (filteredSubMenu.length > 0) {
        acc.push({ ...item, menu: filteredSubMenu })
      }
    } else {
      acc.push(item)
    }

    return acc
  }, [])
}

//
export function buildTreeItems<T extends TreeNode, K extends keyof T>(
  nodes: T[],
  hierarchies: HierarchyRelation[],
  options: {
    idKey?: K
    labelKey?: K
    childrenKey?: K
    iconKey?: K
    defaultIcon?: string
  } = {}
): TreeItemType[] {
  const {
    idKey = 'id' as K,
    labelKey = 'name' as K,
    childrenKey = 'children' as K,
    iconKey = 'icon' as K,
    defaultIcon = 'mdiAccountMultiple'
  } = options

  type NodeWithChildren = T & { [P in K]: P extends typeof childrenKey ? T[] : T[P] }

  // Create a map of nodes for quick lookup
  const nodeMap = new Map<T[K], NodeWithChildren>(
    nodes.map((node) => [node[idKey], { ...node, [childrenKey]: [] } as NodeWithChildren])
  )

  // Build the hierarchy
  hierarchies.forEach(({ parentId, childId }) => {
    const parentNode = nodeMap.get(parentId as T[K])
    const childNode = nodeMap.get(childId as T[K])
    if (parentNode && childNode) {
      parentNode[childrenKey].push(childNode)
    }
  })

  // Find root nodes (nodes that are not children in the hierarchy)
  const childIds = new Set(hierarchies.map((h) => h.childId))
  const rootNodes = Array.from(nodeMap.values()).filter((node) => !childIds.has(node[idKey]))

  // Convert to TreeItemType
  function convertToTreeItem(node: NodeWithChildren): TreeItemType {
    return {
      id: node[idKey],
      label: node[labelKey] as string,
      icon: (node[iconKey] as string) || defaultIcon,
      children: node[childrenKey].length > 0 ? node[childrenKey].map(convertToTreeItem) : undefined,
      ...Object.fromEntries(
        Object.entries(node).filter(
          ([key]) => ![idKey, labelKey, childrenKey, iconKey].includes(key as K)
        )
      )
    }
  }

  return rootNodes.map(convertToTreeItem)
}

export function splitSentences(input: string, separators: string | string[] = '.'): string[] {
  // Check if input is a non-empty string
  if (typeof input !== 'string' || input.trim() === '') {
    //    console.error('Invalid input: Expected a non-empty string')
    return []
  }

  // Ensure separators is an array
  const separatorArray = Array.isArray(separators) ? separators : [separators]

  // Create a regular expression from the separators
  const separatorRegex = new RegExp(`[${separatorArray.map(escapeRegExp).join('')}]`)

  // Split the input into sentences
  const sentences = input
    .split(separatorRegex)
    .map((sentence) => sentence.trim())
    .filter((sentence) => sentence !== '')

  // Check if there are any valid sentences
  if (sentences.length === 0) {
    console.warn('No valid sentences found in the input')
    return []
  }

  return sentences
}

// Helper function to escape special characters for use in a regular expression
export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

// Helper function to escape HTML special characters
export function escapeHTML(str: string): string {
  return str
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;')
}

// Function to generate UL string from sentences
export function generateULString(sentences: string[]): string {
  if (sentences.length === 0) {
    return ''
  }

  const liElements = sentences.map((sentence) => `<li>${escapeHTML(sentence)}</li>`).join('')

  return `<ul>${liElements}</ul>`
}

//
export function logger(title: string, message: any, force: boolean = false) {
  if (import.meta.env.VITE_MODE === 'development') {
    if (
      'all' === import.meta.env.VITE_LOG_TARGET ||
      title.includes(import.meta.env.VITE_LOG_TARGET || force)
    )
      console.log(`${title}= `, message)
  }
}
