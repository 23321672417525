<script lang="ts" setup>
import { useNotification } from '@/composables/useNotification'

const { notifications, removeNotification } = useNotification()
</script>

<template>
  <div class="fixed top-0 left-1/2 transform -translate-x-1/2 z-255">
    <TransitionGroup name="notification">
      <div
        v-for="notification in notifications"
        :key="notification.id"
        class="notification mb-2 p-4 min-w-[400px] w-3/5 rounded shadow-lg"
        :class="{
          'bg-blue-500': notification.type === 'loading',
          'bg-red-500': notification.type === 'error',
          'bg-green-500': notification.type === 'info'
        }"
      >
        {{ notification.message }}
        <button
          v-if="notification.type !== 'loading'"
          class="ml-2 text-white"
          @click="removeNotification(notification.id)"
        >
          &times;
        </button>
      </div>
    </TransitionGroup>
  </div>
</template>

<style scoped>
.notification-enter-active,
.notification-leave-active {
  transition: all 0.5s ease;
}
.notification-enter-from,
.notification-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
</style>
